import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://packagingseo17-821204657301.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (



    <>
    {
      about.map(a=> <section className="customers-solutions overflow-hidden parallax-element" id="about-sec">
        <div className="container container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="customers-solutions__wrapper d-flex flex-column flex-lg-row justify-content-between align-items-center" style={{
                backgroundImage:
                  "url(https://i.ibb.co/fnqQLDf/background.png)",

              }}>
                <div className="customers-solutions__shape wow fadeIn animated" data-wow-delay=".3s">
                  <img src="assets/imgs/customers-solutions/background-shape.svg" alt="image not found" />
                </div>
                <div className="customers-solutions__content">
                  <div className="section-3__title-wrapper">
                    <span className="section-3__subtitle flex-wrap justify-content-start mb-10 mb-xs-5 wow fadeIn animated" data-wow-delay=".1s"><span className="layer" data-depth="0.009">{a.aboutText}</span> </span>
                    <h2 className="section-3__title lg mb-15 wow fadeIn animated" data-wow-delay=".3s">{a.aboutHeading}</h2>
                    <p className="mb-30 wow fadeIn animated" data-wow-delay=".5s">{a.aboutDetails}</p>
                    <ul className="wow fadeIn animated" data-wow-delay=".6s">
                      <li>{a.pointOne}</li>
                      <li>{a.pointTwo}</li>
                      <li>{a.pointThree}</li>
                      <li>{a.pointFour}</li>
                      <li>{a.pointFive}</li>
                    </ul>
                    <div className="rr-btn__wrapper d-flex flex-wrap align-items-center mt-40 mt-sm-35 mt-xs-30 wow fadeIn animated" data-wow-delay=".7s">
                      <a href="#contact-sec" className="rr-btn rr-btn__theme-4">
                        <span className="btn-wrap">
                          <span className="text-one">Contact Us</span>
                          <span className="text-two">Contact Us</span>
                        </span>
                      </a>

                    </div>
                  </div>
                </div>
                <div className="customers-solutions__media rightLeft wow fadeIn animated" data-wow-delay=".3s">
                  <img src={a.aboutImgOne} alt="image not founds" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>)
    }
      

    </>
  );
};

export default AboutSection;
