// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBG0z_3dhR6Lx_7OyRniuuYem9Jd0QpIhY",
  authDomain: "packagingseo17.firebaseapp.com",
  projectId: "packagingseo17",
  storageBucket: "packagingseo17.firebasestorage.app",
  messagingSenderId: "306509403402",
  appId: "1:306509403402:web:11d1a13da514cbe113d6f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;